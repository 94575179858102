<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          class="mb-4"
        >
          <v-img 
            :src="require(`@/assets/${icon}.png`)"             
            :max-width="iconSize"
            class="mx-auto"
            eager
          >
          </v-img>
        </v-col>
        <v-col
          v-if="image"
          :class="`text-${align}`"
          class="mb-4"
          cols="12"
          sm="4"
          md="3"
        >
          <v-img 
            :src="image"
            :lazy-src="image_lr"
            :max-width="iconSize"
            class="ml-auto mr-auto mr-md-0"
            eager
          >
          </v-img>
        </v-col>
        <v-col          
          v-if="image"
          :class="`text-${align}`"
          class="mb-4 ml-4 base-icon-card-text"
        >
          {{ text }}
        </v-col>
      </v-row>
      <v-row
        :justify="justify"
        v-if="icon"
        no-gutters
      >
        <v-col          
          :class="`text-${align}`"
          class="mb-4 base-icon-card-text"
        >
          {{ text }}
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseIconCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      icon: String,      
      image: String,
      image_lr: String,
      text: String,
      size: String,
      mobileSize: String,
    },

    computed: {
      iconSize () {
        return this.$vuetify.breakpoint.mobile
          ? (this.mobileSize ? this.mobileSize : this.size / 2) : this.size
      },
    }
  }
</script>

<style lang="sass">
  .base-icon-card-text
    overflow-wrap: break-word
</style>