<template>
    <icon-base icon-name="ИЛ Кооператив" width="433.7" height="391.2">      
        <path d="M99.5,200.2c-15.2,6.6-30.6,12.4-48.2,8.9c0.9,3.5,1.4,5.5,2,8c19.7,0.4,35.1-6.4,47.4-16.3
          C100.3,200.7,99.8,200.1,99.5,200.2"/>
        <path d="M351.7,121.3c1.1-0.2,2.6-2.6,2.4-3.7c-0.5-2.9-2.1-5.5-2.8-8.4c-0.7-2.8-3.2-4.5-5.6-3
          c-1.4,0.9-2.3,2.4-3.7,3.2c-1.2,0.7-2.7,0.7-5,1.3c1.5-2.9,2.3-4.5,3.2-6.1c-0.2-0.4-0.5-0.7-0.7-1.1c-4.2,1.7-8.5,3.4-12.7,5.2
          c-0.4,0.2-0.8,1-0.9,1.6c-0.7,4.4,3.1,10.7,7.1,11.5c1.2-0.9,2.5-1.4,3.8-1.4c2.2,0.2,4.3,0.9,6.5,1.1
          C346.1,121.8,349,121.9,351.7,121.3"/>
        <path d="M367.7,184.2c-8.9-10.8-18-20-26.4-29.8C339.9,163.9,357.8,184.6,367.7,184.2"/>
        <path d="M316.5,111.6c-0.6,0.1-1.2,0.3-1.7,0.4c-3,5-4.1,10.2-0.6,15.7c2.7,4.2,5.1,8.5,8.2,13.6
          c0.8-1.2,2.5-2.7,2.2-3.7C322.1,128.9,319.2,120.3,316.5,111.6"/>
        <path d="M294.7,12.8c-5.2-0.8-10.4-2.1-15.6-3.9c-9-3.1-18.6-4.8-28.1-6.1l0,0c-44.4-7-44.1,1.1-44.1,1.1
          c8.1,4.1,12.1,7.2,13.9,8.9H0l0.8,1.5l0,0.1c1.1,2.2,2.2,4.3,3.3,6.5l0.2,0.3h0c0.7,1.3,1.3,2.6,2,3.8
          c7.9,14.2,15.9,28.4,23.8,42.5c6,10.6,12,21.3,18.1,31.9c4.4,7.6,8.6,15.4,13.5,22.7c9,13.5-17.5,16.4-24.2,16.3
          c2.5,7.5,9.1,9.7,14.7,12.4c8.6,4.1,17.7,7.3,27.6,6.6c3.9-0.3,3.7,2.8,5,4.7c0.4,0.6-0.1,2.5-0.7,2.8c-6.9,3.3-13.8,6.8-20.9,9.4
          c-7.6,2.8-15.6,4.6-25.3,7.4c0.5,3.4,4.6,5.2,6.3,5c11.3-1.2,21.8-5.4,32.4-9.4c11.9-4.5,20.9-13,30.2-21.2
          c1.6-1.4,3.8-2.1,5.8-3.2c-0.5-0.9-1.1-1.7-1.6-2.6c-2.8-0.6-5.6-1.8-8.4-1.8c-10.3,0-20.6,0.9-30.8,0.3
          c-5.7-0.3-11.2-2.9-16.8-4.4c0-0.8,0.1-1.5,0.1-2.3c4.5-1.2,8.9-2.6,13.4-3.4c8.4-1.5,16.9-0.2,25.5-1.7
          c12.4-2.1,22.9-6.6,31.5-15.1c2.7-2.7,4-7.5,3.7-7.7c-1.9,1.8-3.6,4-5.8,5.3c-9.7,5.8-32.9,12.3-36.1,8.4c3-1.3,5.1-1.9,6.6-3
          c7.7-5.6,15.4-11.1,22.7-17.1c8.4-6.9,15.4-15.7,25.4-20.7c5.7-2.9,11.6-5.6,17.1-8.8c1.7-1,4.1-4.1,3.6-5.1
          c-1.2-2.8-3.6-0.6-5.5,0.2c-0.6,0.2-1.3,0.1-1.9,0.3c-4.7,1.2-9.5,2-14,3.7c-9.5,3.6-18.9,7.7-28.4,11.6c-0.9,0.4-2.2,0.1-3.2,0.1
          c-0.2-0.5-0.3-1-0.5-1.5c2.3-1.5,4.7-3,7-4.4c8-4.9,16.1-9.4,23.8-14.7c7.1-4.9,13.2-11.6,20.7-15.7c12.9-7.1,26.8-11.8,41.8-12.3
          c4.1-0.1,8.1-1.5,12.1-2.3c0-0.4-0.1-0.9-0.1-1.3c-2.2-0.4-4.4-1.1-6.6-1.1c-13.4,0.3-26.7,0.8-39.7,5.2
          c-12.1,4.1-23,9.8-32.3,18.5c-0.8,0.7-2.3,0.6-3.5,0.9c0.3-1.2,0.2-2.7,1-3.6c2.9-3.2,5.5-6.9,9.1-9.1c9.1-5.6,18.1-11.9,28-15.5
          c11.4-4.2,23.6-7.2,36.1-7.2c3.2,0,6.5-0.2,9.6,0.2c5.4,0.8,10.7,2,16.1,3.1c5.7,1.2,11.5,2.4,17.2,3.4c1.2,0.2,3.5-0.5,3.7-1.3
          c0.3-1.3-0.3-3-0.9-4.4c-0.4-1-1.5-1.7-2.3-2.4c-4.9-4.4-9.9-8.9-14.8-13.3c0.1-0.5,0.3-1.1,0.4-1.6c2.3,0.5,4.8,0.5,6.8,1.5
          c10.7,5.6,21.3,11.5,32,17.1c1.3,0.7,3.2,0.1,4.9,0.1c0.1-0.5,0.2-1.1,0.2-1.6c-6.6-4.5-13.2-9-19.8-13.6c0.2-0.4,0.4-0.7,0.6-1.1
          c14.7,6.6,31.5,7.2,44.9,15.5c-5.4,6.4-10,12.3-15,17.8c-1.1,1.2-3.4,1.8-5.2,1.9c-4.1,0.3-8.2-0.3-12.3,0.1
          c-9.6,0.9-19.3,2-28.9,3.2c-5.4,0.7-10.9,1.2-16.1,2.9c-6,1.9-11.6,4.9-17.3,7.4c-6.7,3-13.5,5.8-20,9.2c-4.8,2.5-9.6,5.3-13.8,8.8
          c-5.5,4.5-10.7,9.5-15.3,14.9c-6.2,7.5-11.8,15.5-17.4,23.5c-5,7-10.4,13.9-14.5,21.4c-7.2,13-10.4,27.6-14.7,41.7
          c-2.6,8.4-4.4,17-7,25.4c-1.9,6.5-4.7,12.7-6.6,19.1c-3,10.2-6.4,9.5-15,9.6c-10.2,0.1-20.4-0.7-31.7-1.1c0.2,3.3,3.1,7.2,5.1,7.5
          c10.5,1.3,21.1,1.6,31.6,2.2c0.3,0.7,0.8,1.5,0.9,2.5c1.7,10.1,6.6,17.7,16.6,21.4c6.4,2.4,12.6,5.2,19,7.4
          c8.2,2.8,16.5,5.1,25.5,7.8l0.4,0.8c0.3,0.6,0.7,1.6,1.3,2.4c0.4,0.7,0.8,1.3,1.2,2l59.1,104.7l44.3-78.6c1.7-1.2,3.7-0.7,6.8,1.3
          c2.4,1.6,5,3.1,7,5.1c6.2,6.3,12.2,12.9,18.3,19.4c2.5,2.6,4.9,2.2,6.2-1.2c2.3-5.8,4.8-11.5,6.3-17.5c1.9-7.7,4-15.6,4.1-23.4
          c0.2-13.5-4.1-26.1-10.3-38c-2.3-4.4-2.6-8.8-0.1-13.2c6.8-12.2,13.7-24.4,20.6-36.5c0.6-1,1.8-1.6,2.6-2.3
          c4.3,2.8,8.1,5.6,12.3,7.8c5.4,2.8,11,5.3,16.5,7.7c3.6,1.6,5.5,3.2,4.1,7.9c-0.8,2.6,0.2,6.7,2,8.7c5.2,5.8,12.2,7.5,19.9,6.8
          c3.9-0.3,7.9-0.5,11.7,0.3c4.5,0.9,6.8-0.4,7.6-4.7c0.5-2.8,2.2-4.1,5-4.2c1.9-0.1,3.9,0,5.8,0c6.1,0,11.1-2.3,12.2-7.6
          c1.3-6.4,4.9-11.1,8.2-16.2c4.3-6.7,1.6-12.1-4.9-14.8c-2.3-1-4.9-2.4-6.1-4.3c-6.4-10.2-12.9-20.4-18.5-31
          c-7-13.2-13.3-26.7-24.5-37.2c-1.2-1.1-1-4.7-0.3-6.8c1-3.1,3-5.8,4.7-8.7c1.1-1.9,2-4.3,3.7-5.4c1.4-0.9,3.9-0.2,6-0.2
          c-2.8-5.7-0.7-10.7,3.2-15.5c2.9-3.6,5.2-7.7,7.5-11.7c6.4-11.1,12.6-22.3,19-33.3c2.4-4.1,4.8-8.1,7.2-12.2
          c2.1-3.5,4.2-6.9,6.2-10.4h0l0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0-0.1l4.4-8.1H294.7z M165.7,26.9c-2.4,1.2-4.9,2.4-7.5,3.2
          c-14.8,4.7-25.1,15-34.2,26.9c-8.7,11.4-17.6,22.7-26.7,33.7c-2.1,2.5-5.6,3.9-8.4,5.8c-0.8,0.6-1.3,1.5-2,2.4
          c12.2,3.5,22.5-1.2,32.9-6.9c-0.7,6.5-29.9,28.6-40.5,32.9c-0.2,0.1-0.6,0.1-1,0L21.4,25.4c0.5-0.1,1-0.2,1.5-0.3h143.9
          c0.1,0,0.1,0.1,0.2,0.1C166.6,25.8,166.3,26.6,165.7,26.9 M332,53.1c0.7-3.4,0.8-7,1.3-11.4c11.5,5.9,19.1,14.4,26.1,24
          c-2.1-0.9-4.2-2-6.3-2.8c-3.5-1.4-7.1-2.8-10.7-3.8c-1.1-0.3-2.5,0.9-3.8,1.4c0.5,1.1,0.8,2.3,1.4,3.4c1.5,2.5,3.2,4.9,4.6,7.6
          c0.5,1,0.3,2.4,0.5,3.7c-1.5-0.1-3.7,0.3-4.4-0.5C335.2,68.4,330,62.2,332,53.1 M215.7,364.5l-45.1-79c6.2-4.1,12.1-2.9,17.8-1.6
          c10.4,2.3,20.8,4.7,30.9,7.8c7,2.2,13.5,5.7,20.2,8.6c1,0.4,2.1,0.9,3.1,1.3c3.3,1.3,5.1,2.2,5.8,3.4L215.7,364.5z M309,204.7
          c-5.8,10.2-11.7,20.3-17.7,30.4c-0.5,0.9-1.4,1.7-3,3.8c-1.6-4-2.7-6.7-3.7-9.6c-3.8-10.7-7.5-21.3-11.1-32.1
          c-1-3.1-1.6-6.4-2.2-9.6c-0.2-0.9,0.4-1.9,0.6-2.8c0.9,0.3,2.1,0.5,2.8,1.1c3.1,2.6,5.6,6.3,9,7.7c5.4,2.2,11.4,3,17.2,4.2
          c1.9,0.4,3.9-0.2,5.9-0.1C309.4,198.1,310.7,201.7,309,204.7 M383.1,155.2c4,7.1,6.8,15,10.2,22.4c0.7,1.5,2.3,3.7,3.3,3.5
          c4.2-0.5,4.3,3.1,5.4,5.1c2.3,4.3,3.9,8.9,5.9,13.6c-0.7,0.3-1.7,0.8-2.7,1.1c-6.9,2.4-8,4.2-5.3,10.7c1.2,3,3.4,5.6,5.2,8.3
          c0.5,0,1,0,1.5-0.1c0.4-1.6,0.8-3.2,1.1-4.8c0.3-1.9,0.2-3.9,1-5.5c0.6-1.1,2.5-2.4,3.6-2.2c1.4,0.2,2.8,1.7,3.7,3
          c0.5,0.7-0.1,2.3-0.5,3.3c-1.6,3.5-3.2,7-5,10.3c-0.7,1.3-2,3.1-3.1,3.1c-5.7,0.1-11.6,0.4-17.1-0.9c-2.8-0.7-4.9-4.7-7.3-7.2
          c-4.5-4.6-9.1-9.3-13.7-13.8c-1.1-1-2.7-1.4-4.5-2.3c-1.9,4.5-0.6,7.6,2.6,10.3c5.2,4.5,10.6,8.8,15.4,13.6
          c2.2,2.2,3.4,5.4,4.8,7.8c-5.3,0.8-10.1,2.1-14.8,2.2c-3,0-6.3-1.5-9.1-3c-0.9-0.5-1.4-3.8-0.6-4.8c2.6-3.4,2-6.3-0.6-9
          c-2.8-3-5.6-6.4-9.9-4.8c-1.9-1.3-3.4-2.6-5.1-3.5c-8.2-4.5-16.6-8.6-24.6-13.4c-6.2-3.7-7.5-3.5-5-12.2c0.1-0.4-0.1-0.9-0.2-1.8
          c-3.7,1.1-7.4,2-10.9,3.3c-9,3.2-16.9,2.6-23.6-5.1c-5.9-6.8-13.3-12.6-18-20.1c-7.5-11.9-10.9-25.4-6.4-39.3
          c2-6.3,6-12.1,9.1-18.1c0.5-1,0.9-2,2-4.4c-9-0.2-17.4-1.9-25.1-0.3c-7.3,1.5-14.2,5.8-20.7,9.9c-6,3.8-11.3,9-16.7,13.7
          c-1.4,1.2-3,2.7-3.5,4.4c-2.4,7.3-4.9,14.6-6.6,22.1c-3.6,15.6,1.3,30.4,5.2,45.2c0.2,0.6,1.5,1.5,2,1.3c0.6-0.2,1.5-1.3,1.5-2
          c0-1.7-0.5-3.3-0.8-5c-3.4-15.2-3.7-30.5,1.2-45.1c3.1-9.1,8.9-17.4,18.3-22.2c7.8-4,15.3-8.6,24.4-8.9c2.1-0.1,4.2,0,6.4,0
          c-1.4,4.7-2.6,8.6-3.7,12.5c-3.8,14-2.7,27.7,2.1,41.3c4.9,14,10.1,28,14.7,42.1c1.2,3.6,1,7.6,1.4,11.4c0,0.4-0.1,0.9,0.1,1.2
          c4.4,5.7,4.4,5.7,6.1,13.9c-3.1-2.5-5.5-4.1-7.5-6.2c-1.9-2-3.3-4.5-5.2-6.5c-7.7-8.1-13.6-17.4-18.4-27.3
          c-2.3-4.7-4.1-9.6-6.2-14.4c-2-4.8-4-9.6-6.2-14.3c-0.4-0.9-1.7-1.4-2.7-2c-0.3,1.1-0.9,2.2-0.9,3.3c0.2,11.9,1.6,23.6,5.3,35
          c1.1,3.4,3.2,6.5,4.7,9.8c3.2,7,6,14.2,9.7,20.9c3,5.4,6.9,10.4,10.6,15.4c3.9,5.2,7.9,10.3,12,15.3c4.6,5.6,9.4,11.1,14.1,16.7
          c6.6,7.7,10.7,16.7,11.1,26.6c0.3,5.3-2.4,10.8-4.1,17.1c-5-4.9-8.6-9.3-13-12.4c-9.3-6.6-19-12.5-28.6-18.6
          c-0.3-0.2-0.6-0.2-0.9-0.2c-7.2-1-13.2-5-19.5-8.1c-4.4-2.2-9.3-3.5-14.2-4.3c-6.8-1.2-11.6-4.3-15.6-10
          c-7.6-10.6-12.5-22.1-15.9-34.7c-3.6-13.2-6-26.6-7.1-40.1c-0.4-4.8-0.1-9.7-0.3-14.5c-0.1-1.5-0.7-2.9-1.9-4.3
          c-6,15.8-3.8,32.1-2.6,48.2c1.2,16.4,6.3,32,12.4,47.3c-5.7-0.7-6.2-5.6-8.2-9c-0.9-1.4,0.2-4.4-0.8-5.2c-5.4-4.4-6.7-11-9.5-16.7
          c-0.4-0.7-1.4-1.1-2.6-1.9c-0.1,9.2-0.3,17.8,4.8,25.6c0.8,1.2,0.3,4.2-0.8,5.1c-1.4,1.3-4.1,2.3-5.9,1.9
          c-12.7-2.9-25.4-5.7-37.8-9.5c-7.6-2.4-14.8-6.2-20.2-12.7c-2.6-3.1-3.3-6-1.7-9.6c3.4-8.1,7.3-16.1,10.2-24.4
          c2.6-7.5,4-15.3,6.3-22.9c3.7-12.3,7.3-24.6,11.6-36.6c3.2-9.2,6.7-18.3,11.2-26.9c2.8-5.5,7.7-9.8,11.6-14.8
          c4.5-5.7,9-11.3,13.2-17.1c7.2-10.2,16.7-17.6,27.8-22.9c11.5-5.5,23.3-10.7,35.3-15.1c7.3-2.7,15.2-3.6,22.9-5.1
          c4.1-0.8,8.4-1.1,12.5-2c4.5-1,8.2,0.7,11.5,3.4c0.9,0.7,1.3,2.6,1,3.8c-0.1,0.8-1.7,1.6-2.8,1.8c-9,2-18.2,3.5-27.1,6
          c-10.3,2.9-20.6,6.1-30.4,10.4c-11.5,5-23.2,9.8-31.8,19.8c-4.3,5-10.5,8.4-14.5,13.6c-6.9,9-13.4,18.4-18.7,28.3
          c-6,11.2-11.1,23-15.6,34.9c-3.5,9.3-5.7,19.2-8.4,28.9c-0.1,0.5,0,1.1,1,2c1.3-3,2.7-5.9,3.9-9c3.1-7.9,5.8-16,9.4-23.7
          c4.6-9.8,9.4-19.4,14.9-28.6c4.4-7.3,10-14,15.3-20.8c9.6-12.4,22.2-21.4,36-28.4c8.2-4.2,17.1-7.4,26-9.8
          c11.7-3.2,23.8-5.2,35.7-7.7c5.3-1.1,9.8-0.8,12.1,5.1c0.2,0.4,0.4,0.9,0.8,1.1c0.5,0.3,1.1,0.5,3,1.2c0.1-4.7,0.2-8.9,0.2-13.1
          c-0.1-8.6,4.2-15.4,8.7-22.2c0.3-0.5,1.4-0.5,2.7-0.8c0.7,5.9,1.3,11.6,2,17.2c0.2,1.8,0.5,3.7,1.3,5.3c0.3,0.5,3.1,0.6,3.5,0
          c2.8-3.7,5.4-7.6,7.9-11.6c0.7-1.1,0.7-2.7,1-4.2c-3.1,1-5.3,1.7-7.4,2.4c-0.1-2.2-0.4-4.3-0.2-6.5c1.4-10.7,7.3-18.6,15.8-24.8
          c2.6-1.9,5.4-3.7,8.2-5.5c0.3,0.4,0.7,0.8,1,1.3c-1.7,5.4-4.1,10.6-5.1,16.1c-1.3,7.3-1.9,14.8-1.8,22.1c0.1,4.2-0.6,7.5-3.2,10.6
          c-0.9,1.1-1.2,2.6-1.8,3.9c1.1,0,2.3,0,3.4,0.1c4.7,0.5,8.8,1.5,9.7,7.3c0.7,4.3,2.3,8.5,3.8,13.9c1.8,0,4.9,0.4,7.8-0.1
          c4.7-0.7,7,2.3,8.5,5.6c2.2,5.1,4.8,10.4,5.3,15.8c0.7,7.7,5.9,11.1,10.6,15.9C374.4,140.8,379,148,383.1,155.2 M367.7,101.6
          c-1.4,1.8-2,4.2-3.6,7.5c-1.6-3.9-3.2-6.8-4-9.8c-1.6-6.1-6-8.1-11.5-9.5c-2.6-0.6-4.8-2.6-7-4.2c-0.5-0.4-0.1-2-0.2-3
          c0.9,0,2-0.2,2.7,0.2c4.3,2.2,8.6,4.5,13.3,7c3.6-6.9-5.9-11.5-2.9-19.1c4.7,5.2,8.4,10.3,13.1,14.2
          C374.9,90.8,372.1,95.9,367.7,101.6 M393.9,58.5c-4.5,7.8-9.1,15.5-13.8,23.1c-1.2,2-2.9,2.5-4.2-0.3c-1.1-2.4-2.5-4.7-3.3-7.2
          c-2.7-8.5-8-15-13.9-21.5c-5.1-5.7-10.7-10.3-17-14.3c-3.6-2.3-5.4-5.4-4.1-9.7c0,0,0,0,0,0c0.6-2.1,2.6-3.4,4.8-3.4h71.1
          C412.6,26.5,399.5,48.9,393.9,58.5"/>
    </icon-base>
</template>

<script>
import IconBase from '@/components/IconBase.vue'

export default {
  components: {
    IconBase,
  }
}
</script>
