// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (to.hash) return { selector: to.hash }
  //   if (savedPosition) return savedPosition

  //   return { x: 0, y: 0 }
  // },
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {      
      const position = { }
      position.behavior = 'smooth'
      
      var promise = false

      // specify offset of the element
      if (to.hash) {
        position.selector = to.hash
        //position.offset = { y: 60 }
        promise = true
      }      

      if (promise) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(position)
          }, 500)
        })
      }

      return position
    } else if (to.path == '/') {
      return { x: 0, y: 0, behavior: 'smooth' }
    }

    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [        
        {
          path: '',
          name: 'Главная',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'about',
          name: 'О компании',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/main.jpg') },
        },
        {
          path: 'price',
          name: 'Услуги и цены',
          component: () => import('@/views/price/Index.vue'),
          meta: { src: require('@/assets/main.jpg') },
        },        
        {
          path: 'news',
          name: 'Статьи и новости',
          component: () => import('@/views/news/Index.vue'),
          meta: { src: require('@/assets/main.jpg') },
        },
        {
          path: 'contact-us',
          name: 'Контакты',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/main.jpg') },
        },        
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
