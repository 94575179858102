<template>
  <v-dialog v-model="show" scrollable max-width="600px">
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="clearForm">
        Отправить заявку
      </v-btn>
    </template> -->
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-alert
            class="text-center"
            :value="show_alert"
            type="error"
            dismissible
            outlined
            border="left"
            @input="closeAlert"
            >{{ alert_message }}</v-alert
          >
          <form style="max-height: 60%">
            <v-text-field v-model="name" label="Имя"></v-text-field>
            <v-text-field
              v-model="phone"
              :error-messages="phoneErrors"
              label="Телефон"
              required
              @input="$v.phone.$touch()"
              @blur="$v.phone.$touch()"
            ></v-text-field>
            <v-textarea v-model="text" label="Текст запроса"></v-textarea>
            <v-checkbox
              v-model="agree"
              :error-messages="agreeErrors"
              label="Настроящим даю свое согласие на обработку персональных данных"
              required
              @change="$v.agree.$touch()"
              @blur="$v.agree.$touch()"
            ></v-checkbox>
          </form>
          <small class="d-block pb-4">
            Этот сайт защищен reCAPTCHA.
            <a href="https://policies.google.com/privacy"
              >Политика конфиденциальности</a
            >
            и
            <a href="https://policies.google.com/terms"
              >Условия использования</a
            >
            Google.
          </small>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="submitOrder"> Отправить </v-btn>
        <v-btn color="primary" text @click="show = false"> Закрыть </v-btn>        
      </v-card-actions>
    </v-card>    
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, {
  siteKey: "6LcdbesgAAAAAC7peWFepOiDQMq-NtiqU9F0MYnY",
  loaderOptions: {
    autoHideBadge: true,
  },
});

import EventBus from '@/mixins/event-bus'

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

const isPhone = (value) => /^(\+7|8)\d{10}$/.test(value);

const axios = require("axios");

export default {
  name: 'BaseOrderForm',

  inject: ['theme'],

  props: {
    subtitle: String,
    title: {
      type: String,
      default: 'Заявка',
    },
    //value: Boolean,
  },

  mixins: [validationMixin],

  validations: {
    phone: { required, isPhone },
    agree: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    name: "",
    phone: "",
    text: "",
    agree: true,
    alert_message: "",
    show_alert: false,    
    show: false,
  }),

  methods: {
    showAlert(message) {
      this.alert_message = message;
      this.show_alert = true;
    },

    showSnack(message) {
      EventBus.$emit('show-snack', message);      
    },

    closeAlert() {
      this.show_alert = false;
    },

    async submitOrder() {
      this.closeAlert();

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      await this.$recaptchaLoaded();

      const token = await this.$recaptcha("order");

      axios
        .post("/api/order", {
          token: token,
          name: this.name,
          phone: this.phone,
          text: this.text,
          flat: this.selectedFlat,
        })
        .then(({ data }) => {
          if (data.result) {
            this.show = false
            this.clearForm()
            this.showSnack(data.message)
          } else {
            this.showAlert(data.message)
          }
        })
        .catch((e) => {
          this.showAlert(e);
        });
    },

    clearForm() {
      this.$v.$reset()
      this.closeAlert()
      this.name = ""
      this.phone = ""
      this.text = ""
      this.agree = true
    },
  },

  mounted() {
    var self = this
    EventBus.$on('show-order-form', function () {      
      self.show = true
    })
  },

  computed: {
    // show: {
    //   get() {
    //     return this.value
    //   },
    //   set(value) {
    //     this.$emit("input", value)
    //   },
    // },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required &&
        errors.push("Укажите телефон в формате +79XXXXXXXXX");
      !this.$v.phone.isPhone &&
        errors.push("Укажите телефон в формате +79XXXXXXXXX");
      return errors;
    },

    agreeErrors() {
      const errors = [];
      if (!this.$v.agree.$dirty) return errors;
      !this.$v.agree.checked &&
        errors.push(
          "Подтвердите свое согласие на обработку персональных данных"
        );
      return errors;
    },
  },
};
</script>

<style scoped lang="sass">
</style>